.nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 85.333px;
    background: white;
    box-shadow: 0px 5.333px 80px 0 rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 75px;
    z-index: 9999; 
  }
  
  
  /* Navbar Content */
  .navbar--content {
    color: purple;;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;  
    font: bolder;
    border-bottom: 3px solid transparent;
  }
  